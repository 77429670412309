.date-formatter-test .auto-update-control {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.date-formatter-test .auto-update-control input[disabled] {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.date-formatter-test .auto-update-toggle {
  background-color: #8e44ad;
  flex-shrink: 0;
}

.date-formatter-test .auto-update-toggle.active {
  background-color: #27ae60;
}

.date-formatter-test .auto-update-toggle:hover {
  background-color: #9b59b6;
}

.date-formatter-test .auto-update-toggle.active:hover {
  background-color: #2ecc71;
}

.date-formatter-test {
  margin: 20px auto;
  padding: 20px;
  font-family: inherit;
  color: inherit;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.date-formatter-test h2 {
  margin-top: 0;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
  color: inherit;
}

.date-formatter-test h3 {
  margin-top: 20px;
  margin-bottom: 15px;
  color: inherit;
  font-size: 2.0rem;
}

.date-formatter-test .test-form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
}

.date-formatter-test .form-section {
  flex: 1;
  min-width: 300px;
  background-color: white;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.date-formatter-test .form-group {
  margin-bottom: 15px;
}

.date-formatter-test .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.date-formatter-test .form-group input,
.date-formatter-test .form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1.5rem;
}

.date-formatter-test .button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.date-formatter-test button {
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 1.5rem;
  transition: background-color 0.2s;
}

.date-formatter-test button:hover {
  background-color: #2980b9;
}

.date-formatter-test button.small {
  padding: 4px 8px;
  font-size: 1.4rem;
}

.date-formatter-test button.active {
  background-color: #27ae60;
}

.date-formatter-test button.primary {
  background-color: #2ecc71;
  font-weight: bold;
}

.date-formatter-test button.primary:hover {
  background-color: #27ae60;
}

.date-formatter-test .form-action {
  margin-top: 20px;
}

.date-formatter-test .results-section {
  background-color: white;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  overflow-x: auto;
}

.date-formatter-test .results-table {
  width: 100%;
  border-collapse: collapse;
}

.date-formatter-test .results-table th {
  text-align: left;
  padding: 12px;
  background-color: #f5f5f5;
  border-bottom: 2px solid #ddd;
}

.date-formatter-test .results-table td {
  padding: 10px 12px;
  border-bottom: 1px solid #eee;
}

.date-formatter-test .results-table tr:hover {
  background-color: #f9f9f9;
}

.date-formatter-test .result-value {
  font-family: monospace;
  background-color: #f5f5f5;
  padding: 4px 6px;
  border-radius: 3px;
  word-break: break-all;
}

.date-formatter-test code {
  font-family: monospace;
  background-color: #f5f5f5;
  padding: 2px 4px;
  border-radius: 3px;
}

.date-formatter-test .demo-info {
  background-color: #f0f7fb;
  border-left: 5px solid #3498db;
  padding: 15px;
  border-radius: 0 6px 6px 0;
}

.date-formatter-test .demo-info ul {
  margin-top: 10px;
  padding-left: 20px;
}

.date-formatter-test .demo-info li {
  margin-bottom: 6px;
}
