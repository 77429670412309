$ember-power-select-border-color: lightgrey;

$orange-primary: #ff9800;
$orange-dark: #f57c00;

$fontStack: Helvetica, arial, sans-serif;
$backgroundColor: lightgray;
$backgroundOddColor: #c3c3c3;
$secondaryBackgroundColor: white;
$headersFontColor: rgb(102, 102, 102);
$accentColor: #91abab;
$accentDarkColor: cadetblue;
$alertColor: #a94442;
$border: 1px solid white;
$dayBorder: 2px solid $accentDarkColor;
$timeBarBorder: 1px solid $accentDarkColor;


$slotHeight: 48px;
$slotHeightThreeQuartersSlotsPerHour: $slotHeight*0.75;
$slotHeightFourSlotsPerHour: 20px;
$slotHeightTwoSlotsPerHour: 30px;
// Musi być podzielna przez 4.
$navbarCollapseWidth: 1250px;
$navbarHeight: 55px;
$scheduleTimeBarOffset: 50px;
$dualTimeBarOffset: 2*$scheduleTimeBarOffset;
$scheduleNavigationHeight: 55px;
$scheduleHeaderHeight: 7.5rem;
$maxPalletsOffset: 1.6rem;
$maxPalletsOffsetHD: 3.1rem;

$templateNavigationHeight: 200px;

$scheduleHeaderHeightHD: 9rem;
$hdSlotHeight: $slotHeight*3;
$hdSlotHeightFourSlotPerHour: $slotHeightFourSlotsPerHour*3;
$hdSlotHeightTwoSlotPerHour: $slotHeightTwoSlotsPerHour*3;
$hdFontSize: 19px;
$hdTimeNavWidthSm: 45px;
$hdFontSizeSm: 11px;
$hdTimeNavWidth: 10rem;
$font-12: 12px;
$font-10: 10px;

$standardPadding: 1rem;
$xSmallPadding: 2px;
$smallPadding: 4px;
$standardPadding: 1rem;
$bigPadding: 3rem;
$windowFontSize: 12px;
$windowFontSizeSm: 9px;
$infoColor: steelblue;
$successColor: #3c763d;
$warningColor: #FF7033;
$textColor: #444;
$textColorLight: floralwhite;
$borderColor: floralwhite;
$borderColorDark: #d2cdcd;
$initialColor: #39B3D7;
$lateColor: #D2322D;
$transportCombiningMarker: black;
$disabledColor: #444;
$disabledColorLight: #888;
$disabledColorAlt: #333;
$dedicatedColor: rgba(223, 223, 203, .7);
$dedicatedTitleColor: rgba(223, 223, 203, .9);
$templateColor: #565656;
$dedicatedTemplateColor: $dedicatedColor;
$dedicatedTemplateTitleColor: rgb(223, 223, 203);
$defaultBackgroundColor: white;
$primaryColour: #2ea5e2;
$linkColor: #337A94;
$expiredColor: #999999;
$redColor: red;

$dedicatedWindowZIndex: 1;
$defaultWindowZIndex: 2;
$dedicatedWindowHoverZIndex: 3;

