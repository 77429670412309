@import "../abstract/variables";

// Base layout structure
.schedule-container {
  margin: 0;
  padding: 0;

  .form-group .wide-input {
    @include mediaQuery(tiny-phone) {
      width: 100%;
    }
  }

  .schedule-warehouse-select-container {
    max-width: 30rem;

    @include mediaQuery(phone) {
      max-width: 180px;
    }
  }
}

// Header and navigation components
.schedule-header {
  position: sticky;
  width: 100%;
  z-index: 100;
  background: $backgroundColor;
  height: $scheduleHeaderHeight + .1rem;
  top: $scheduleNavigationHeight + $navbarHeight;
}

.schedule-navigation {
  margin: 0;
  position: sticky;
  top: $navbarHeight;
  background: $defaultBackgroundColor;
  height: $scheduleNavigationHeight;
  width: 100%;
  padding: 1rem 1.5rem 1rem 1rem;
  z-index: 99;

  .row {
    padding-bottom: $standardPadding;
  }

  div.pull-left {
    input.sum-of-pallets {
      width: 58px;

      &.too-much-pallets {
        color: $lateColor;
      }
    }
  }
}

// Template specific styles
div.schedule-template {
  .schedule-navigation {
    height: $templateNavigationHeight;
  }

  .schedule-header {
    top: $templateNavigationHeight + $navbarHeight;
    width: 100%;
  }
}

// Footer styles
div.schedule-footer {
  margin: 1rem;

  .schedule-companies-div {
    label {
      margin-top: 0.75rem;
      margin-right: 1rem;
    }

    i {
      margin-top: 0.75rem;
      margin-left: 1rem;
    }
  }
}


.dual-time-bars {
  // Apply the wider offset to elements that depend on time-bar width
  .schedule .schedule-header .days-headers {
    padding-left: $dualTimeBarOffset;
  }

  .info-fixed {
    margin-left: $dualTimeBarOffset;
  }

  // Adjust calendar position for dual time bars
  .schedule .schedule-body .schedule-calendar {
    margin-left: $dualTimeBarOffset;
  }
}

// Main schedule component
.schedule {
  font-family: $fontStack;
  background-color: $backgroundColor;

  .schedule-windows {
    display: none;
  }

  // Header day and ramp styles
  .schedule-header {
    .days-headers {
      float: left;
      width: 100%;
      padding-left: $scheduleTimeBarOffset;
      font-size: 12px;

      .day-header {
        float: left;

        &.odd {
          background-color: $backgroundOddColor;
        }

        .day-label {
          width: 100%;
          color: $headersFontColor;
          font-weight: bold;
          padding: 1rem 0;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .ramp-header {
          float: left;
          background-color: $backgroundColor;

          &:first-child {
            border-left: $dayBorder;
          }

          &:not(:first-child) {
            border-left: $border;
          }

          &.even {
            background-color: darken($backgroundColor, 3%);
          }

          .ramp-label {
            color: $headersFontColor;
            text-align: center;
            white-space: nowrap;
            font-weight: bold;
          }
        }

        .warehouse-zone-header {
          display: inline-block;
          padding: $xSmallPadding $smallPadding;
          height: 20px;
          float: left;
          font-weight: bold;
          font-size: $windowFontSize;
          overflow: hidden;
        }
      }
    }
  }

  // Schedule body components
  .schedule-body {
    float: left;
    width: 100%;
    overflow: hidden;

    &.double-max-pallets {
      margin-top: $scheduleHeaderHeight + 2 * $maxPalletsOffset;
    }

    .time-bar > .time-zone-label {
      position: absolute;
      transform: translateY(-15px);
      padding-left: 5px;
    }

    // Time bar on the left
    .time-bar {
      width: $scheduleTimeBarOffset;
      float: left;
      font-size: 14px;
      z-index: 98;
      border-right: $timeBarBorder;

      .time-label {
        height: $slotHeight;
        background-color: $backgroundColor;
        color: $headersFontColor;
        vertical-align: middle;
        text-align: center;
        line-height: $slotHeight;

        // Various slot height variations
        &.slot-height-four-slots-per-hour {
          padding-top: 1px;
          height: $slotHeightFourSlotsPerHour;
          line-height: $slotHeightFourSlotsPerHour;
        }

        &.slot-height-two-slots-per-hour {
          height: $slotHeightTwoSlotsPerHour;
          line-height: $slotHeightTwoSlotsPerHour;
        }

        &.slot-height-three-quarters-slots-per-hour {
          height: $slotHeightThreeQuartersSlotsPerHour;
          line-height: $slotHeightThreeQuartersSlotsPerHour;
        }

        // One-slot-per-X-hours variations
        @for $i from 1 through 5 {
          $multiplier: if($i == 1, 1.5, $i);
          &.slot-height-one-slot-per-#{if($i == 1, 'one-and-half', $i)}-hour#{if($i > 1, 's', '')} {
            height: $slotHeight * $multiplier;
            line-height: $slotHeight * $multiplier;
          }
        }
      }
    }

    // Calendar/grid component
    .schedule-calendar {
      margin-left: $scheduleTimeBarOffset;
      background-color: $backgroundColor;

      .day {
        float: left;

        &.odd {
          background-color: $backgroundOddColor;
        }

        .ramp {
          float: left;

          &:first-child {
            border-left: $dayBorder;
          }

          &:not(:first-child) {
            border-left: $border;
          }

          &.even {
            background-color: darken($backgroundColor, 3%);
          }

          .time-slot {
            height: $slotHeight;
            border-bottom: 1px solid rgb(218, 218, 218);

            &.current-hour {
              border-bottom: none;
            }

            // Slot height variations
            &.slot-height-four-slots-per-hour {
              height: $slotHeightFourSlotsPerHour;

              .full-hour-border {
                border-top: 2px solid white;
              }

              .minutes-15 {
                height: $slotHeightFourSlotsPerHour;
              }
            }

            &.slot-height-two-slots-per-hour {
              height: $slotHeightTwoSlotsPerHour;

              .full-hour-border {
                border-top: 2px solid white;
              }

              .minutes-30 {
                height: $slotHeightTwoSlotsPerHour;
              }
            }

            // One-slot-per-X-hours variations with their minutes heights
            @for $i from 1 through 5 {
              $hourText: if($i == 1, 'one-and-half', $i);
              $minutes: if($i == 1, 90, $i * 60);

              &.slot-height-one-slot-per-#{$hourText}-hour#{if($i > 1, 's', '')} {
                height: $slotHeight * if($i == 1, 1.5, $i);

                @if($i == 1 or $i > 2) {
                  .minutes-#{$minutes} {
                    height: $slotHeight * if($i == 1, 1.5, $i);
                  }
                }
              }
            }

            // Minutes containers
            .minutes {
              // Explicitly define each minute variation
              &-60 {
                height: $slotHeight;
              }

              &-120 {
                height: $slotHeight * 2;
              }

              &-180 {
                height: $slotHeight * 3;
              }

              &-240 {
                height: $slotHeight * 4;
              }

              &-300 {
                height: $slotHeight * 5;
              }
            }
          }
        }
      }
    }

    // Current hour styling
    .current-hour {
      background-color: $accentColor;
      color: snow;

      &.time-label {
        font-size: large;
        background-color: $accentColor;
        color: snow;
        border-color: snow;
      }
    }
  }

  // Clear float utility
  div.cleaner {
    clear: both;
  }

  // Time window components
  .time-window-content {
    z-index: 1;

    &.auto-blockade.is-locked {
      z-index: 2;
      background-color: $disabledColorAlt;

      .time-window {
        cursor: not-allowed;
        background-image: none;
      }
    }

    &.is-locked {
      z-index: 2;

      .time-window {
        cursor: not-allowed;
        background-image: repeating-linear-gradient(45deg,
          transparent,
          transparent 15px,
          rgba(0, 0, 0, .075) 25px,
          rgba(0, 0, 0, .1) 40px);
      }
    }
  }

  // Time window styling
  .time-window {
    cursor: pointer;
    height: 100%;
    word-break: break-all;
    width: 100%;
    border-right-style: none;
    border-left-style: none;
    border-top: 1px solid $borderColor;
    opacity: 0.9;
    filter: alpha(opacity=90); /* For IE8 and earlier */
    color: white;

    @media (max-width: 1399px) {
      line-height: 10px;
      font-size: $windowFontSizeSm;
    }

    @media (min-width: 1400px) {
      font-size: $windowFontSize;
    }

    &:hover {
      opacity: 1;
      filter: alpha(opacity=100);
    }

    // Helper classes
    .no-select {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    // Special markers
    &.mark-delayed {
      border: 2px solid $lateColor;
    }

    &.mark-has-transport-combining:before,
    &.mark-has-transport-combining-with-delayed:before {
      position: absolute;
      content: "";
      bottom: 0;
      right: 0;
      width: 0;
      height: 0;
      border-left: 30px solid transparent;
      border-bottom: 30px solid $transportCombiningMarker;
      z-index: -1;
    }

    // Window components
    .highlighted {
      color: white;
      font-weight: 700;
      text-transform: uppercase;
    }

    .ui-resizable-s {
      height: 10px;
    }

    .time-window-component {
      height: 100%;
    }

    @media (min-width: 1024px) {
      .desc-left {
        float: left;
      }

      .desc-right {
        float: right;
      }
    }

    .title {
      margin-top: 0;
      padding: $smallPadding $xSmallPadding 0;
      line-height: 1;
    }

    .content {
      padding: 0 $xSmallPadding;
      line-height: 1;

      @media (max-width: 1399px) {
        font-size: $windowFontSizeSm;
      }

      @media (min-width: 1400px) {
        font-size: $windowFontSize;
      }
    }

    .left-side-area {
      width: 10%;
      height: 100%;
      margin: 0;
      position: relative;

      @media (max-width: 1080px) {
        display: none;
      }
    }

    .step-type-arrow {
      position: absolute;
      top: 50%;
      left: 25%;
      transform: translate(0, -120%);
      height: 10px;
      font-size: 25px;
    }

    div.window-note {
      color: white;
      padding: 2px 4px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    button {
      &.note-button,
      &.indexes-details-button {
        pointer-events: all;
        border: none;
        background: none;
        color: white;
        padding: 0;
      }

      &.indexes-details-button {
        @media only screen and (max-width: 1023px) {
          font-size: 200%;
          position: absolute;
          top: 0;
          right: 1%;
        }
      }
    }
  }

  // Window state variants
  .window-state {
    &-with-transport {
      background: $initialColor;
      z-index: $defaultWindowZIndex;
    }

    &-disabled {
      background-color: $disabledColor;
      z-index: $defaultWindowZIndex;
    }

    &-dedicated {
      background-color: $dedicatedColor;
      border-bottom: 1px solid snow;
      z-index: $dedicatedWindowZIndex;

      .title {
        background: $dedicatedTitleColor;
        color: $textColor;
      }
    }

    &-template {
      background-color: $templateColor;
      z-index: $defaultWindowZIndex;
    }

    &-dedicated-template {
      background-color: $dedicatedTemplateColor;
      z-index: $dedicatedWindowZIndex;

      .title {
        background: $dedicatedTemplateTitleColor;
        color: $textColor;
      }
    }
  }
}

// HD mode modifications
.schedule.hd-mode {
  .schedule-navigation {
    top: 0px;
  }

  .left-side-area {
    @media (max-width: 1130px) {
      display: none;
    }
  }

  .schedule-header {
    height: $scheduleHeaderHeightHD;
    top: $scheduleNavigationHeight;

    .days-headers {
      @media (max-width: 1023px) {
        padding-left: $hdTimeNavWidthSm;
        font-size: $hdFontSizeSm;
      }

      @media (min-width: 1024px) {
        padding-left: $hdTimeNavWidth;
        font-size: $hdFontSize;
      }
    }
  }

  .schedule-body {
    overflow-y: hidden;

    .time-bar {
      @media (max-width: 1023px) {
        width: $hdTimeNavWidthSm;
        font-size: $hdFontSizeSm;
      }

      @media (min-width: 1024px) {
        width: $hdTimeNavWidth;
        font-size: $hdFontSize;
      }

      .time-label {
        height: $hdSlotHeight;
        line-height: $hdSlotHeight;

        // HD mode slot height variations
        &.slot-height-four-slots-per-hour {
          height: $hdSlotHeightFourSlotPerHour;
          line-height: $hdSlotHeightFourSlotPerHour;
        }

        &.slot-height-two-slots-per-hour {
          height: $hdSlotHeightTwoSlotPerHour;
          line-height: $hdSlotHeightTwoSlotPerHour;
        }

        // One-slot-per-X-hours HD variations
        @for $i from 1 through 5 {
          $multiplier: if($i == 1, 1.5, $i);
          &.slot-height-one-slot-per-#{if($i == 1, 'one-and-half', $i)}-hour#{if($i > 1, 's', '')} {
            height: $hdSlotHeight * $multiplier;
            line-height: $hdSlotHeight * $multiplier;
          }
        }
      }
    }

    .schedule-calendar {
      margin-left: 0;

      @media (max-width: 1023px) {
        padding-left: $hdTimeNavWidthSm;
      }

      @media (min-width: 1024px) {
        padding-left: $hdTimeNavWidth;
      }

      .day .ramp .time-slot {
        height: $hdSlotHeight;
        width: 100%;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid rgb(218, 218, 218);

        // HD mode slot height variations with minutes
        &.slot-height-four-slots-per-hour {
          height: $hdSlotHeightFourSlotPerHour;

          .minutes-15 {
            height: $hdSlotHeightFourSlotPerHour;
          }
        }

        &.slot-height-two-slots-per-hour {
          height: $hdSlotHeightTwoSlotPerHour;

          .minutes-30 {
            height: $hdSlotHeightTwoSlotPerHour;
          }
        }

        // One-slot-per-X-hours HD variations
        @for $i from 1 through 5 {
          $hourText: if($i == 1, 'one-and-half', $i);
          $multiplier: if($i == 1, 1.5, $i);

          &.slot-height-one-slot-per-#{$hourText}-hour#{if($i > 1, 's', '')} {
            height: $hdSlotHeight * $multiplier;
            line-height: $hdSlotHeight * $multiplier;
          }
        }

        // HD minute slots
        .minutes {
          &-15 { height: $hdSlotHeight/4; }
          &-30 { height: $hdSlotHeight/2; }
          &-60 { height: $hdSlotHeight; }
          &-90 { height: $hdSlotHeight*1.5; }
          &-120 { height: $hdSlotHeight*2; }
          &-150 { height: $hdSlotHeight*2.5; }
          &-180 { height: $hdSlotHeight*3; }
          &-240 { height: $hdSlotHeight*4; }
          &-300 { height: $hdSlotHeight*5; }
        }
      }
    }
  }

  // HD time window styling
  .time-window {
    line-height: normal;
    width: 100%;

    .highlighted {
      color: white;
      font-weight: 700;
      text-transform: uppercase;
    }

    .title {
      color: white;

      @media (max-width: 1023px) {
        font-size: $hdFontSizeSm;
      }

      @media (min-width: 1024px) {
        font-size: $hdFontSize;
      }
    }

    .desc-left {
      float: left;
    }

    .desc-right {
      float: right;
    }

    .window-state-dedicated .window-state-dedicated-template {
      color: $textColor;
    }

    .content {
      color: white;

      @media (max-width: 1023px) {
        font-size: $hdFontSizeSm;
      }

      @media (min-width: 1024px) {
        font-size: $hdFontSize;
      }
    }

    div.window-note {
      color: white;
      padding: 2px 4px;
    }

    button.note-button {
      border: none;
      background: none;
      color: white;
      padding: 0;
    }
  }
}

// Additional global components
.current-hour-frame {
  height: 0;
  width: 100%;
  border: 1px solid $accentDarkColor;
  z-index: 98;
  top: 1239px;
  position: absolute;
}

.minutes-slot {
  position: relative;
}

.info-fixed {
  position: fixed;
  bottom: 0;
  margin-left: $scheduleTimeBarOffset;
  z-index: 99;
}

.time-zone-label {
  font-size: 1.2rem;
  font-weight: bold;
  color: #555;
}

// Media queries for responsive design
@media (max-width: 767px) {
  .navbar-left {
    float: left !important;
  }

  .responsive-hide-lvl2 {
    display: none;
  }

  div.popover {
    top: $navbarHeight !important;
    position: fixed;
    overflow-y: scroll;
    max-height: 80vh;

    div.arrow {
      display: none;
    }
  }

  div.schedule-navigation {
    > .form-inline {
      .ember-power-select {
        max-width: 10rem;
        display: inline-block;
        top: 0.65em;
      }

      .date-chooser {
        display: inline;
      }
    }

    div.pull-left input.sum-of-pallets {
      display: inline;
    }
  }

  .form-group.small-schedule {
    display: inline-block;
    margin-bottom: 0;
    margin-top: 0.65em;
  }

  .background-login-img > img {
    display: block;
    height: auto;
    max-width: 70%;
    width: auto;
  }
}

@media screen and (max-width: 1600px) {
  .not-wide-screen-hide {
    display: none;
  }
}

@media (max-width: $navbarCollapseWidth) {
  div.schedule-navigation {
    top: 0;
  }

  div.schedule-template {
    .schedule-navigation {
      height: 210px;
    }
    .schedule-header {
      top: 210px;
    }
  }

  .schedule {
    .schedule-header {
      top: $scheduleNavigationHeight;
    }
  }

  @include mediaQuery(phone) {
    div.schedule-template {
      .schedule-navigation {
        height: 400px;
      }
      .schedule-header {
        top: 400px;
      }
    }
  }
}

@include mediaQuery(tab-land) {
  // Dla urządzeń <= tab-land musimy umożliwić scrollowanie harmonogramu w poziomie
  // dlatego nie możemy używać już sticky schedule-header
  .schedule-navigation {
    z-index: 101 !important;
  }
  .schedule-body-container {
    overflow-x: scroll;
  }
  .schedule-header {
    top: 0 !important;
  }
}
